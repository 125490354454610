<template>
  <div class="inv">

    <p>Here you can find the details of your invoices.</p>

    <table class="inv-table">
      <thead>
        <tr>
          <th>Invoice ID</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>#INV-001</td>
          <td>2025-01-15</td>
          <td>$19.99</td>
          <td>Paid</td>
          <td>
            <UiButton
              :name="'View'"
              :type="'secondary'"
              />      
          </td>
        </tr>
        <tr>
          <td>#INV-002</td>
          <td>2025-02-10</td>
          <td>$29.99</td>
          <td>Pending</td>
          <td>
            <UiButton
              :name="'Pay Now'"
              :type="'secondary'"
              />      
          </td>
        </tr>
      </tbody>
    </table>

    <div class="inv-notes">
      <h3>Note:</h3>
      <p>This table shows sample data for demonstration. Please select an invoice to view or pay.</p>
    </div>

    <div class="inv-actions">

      <UiButton
        :name="'Pay Selected'"
        :type="'secondary'"
        />      

      <UiButton
        :name="'Download All'"
        :type="'secondary'"
        />

    </div>
  </div>
</template>

<script setup lang="ts">
 
import type { User } from '@/types/user'

const { t } = useI18n();
const {$api, $popup} = useNuxtApp()

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();
 

</script>


<style scoped>

.inv {
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.inv h2 {
  margin-bottom: 10px;
  text-align: center;
}

.inv-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
}

.inv-table thead {
  background-color: #f4f4f4;
}

.inv-table th {
  font-weight: 600;
}

.inv-table th,
.inv-table td {
  padding: 12px 16px;
  text-align: left;
}

.inv-table tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.inv-table tbody tr:hover {
  background-color: #f1f1f1;
}

.inv-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #ccc;
  font-size: 0.9rem;
  margin-right: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.inv-button:hover {
  background-color: #ddd;
}

.inv-notes {
  margin-top: 20px;
}

.inv-notes h3 {
  margin-bottom: 5px;
}

.inv-actions {
  display: flex;
  justify-content: center;
  gap:0.5rem;
  margin-top: 20px;
  text-align: center;
}

.inv-actions .inv-button {
  margin: 0 5px;
}


@container pb (max-width: 768px) {
  /* overflow table */

  .inv-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>
